import axios, { AxiosError } from "axios";
import {
  LoadNotification,
  LoadNotificationForSessionError,
} from "./commonUtils";
import { baseURL } from "../routes/routeConfig";
import {hostname} from "../configs/clientConfig";
const api = axios.create({
  baseURL: "http://211.234.106.53:8080/ords/annapurna/api/",
  withCredentials: false,
});
// const api2 = axios.create({
//   baseURL: "http://211.234.106.53:5000/api/",
//   withCredentials: true,
// });

export const api2 = axios.create({
  // baseURL: "http://211.234.106.53:8080/ords/annapurna/api/",
  //baseURL: "https://mall.annapurna.co.kr/api/",
  baseURL: hostname.includes("corealpine.kr") ? "https://b2bmall.corealpine.kr/api" : `https://mall.annapurna.co.kr/api/`,
  // baseURL: "https://anna.modneycare.com/api/",
  withCredentials: true,
});

// const myinfo_saver = () => {
//   localStorage.setItem("SOWJDQH", )
// };

//////////////// USER ////////////////

export const loginFetcher = (data: loginData) => {
  return api2.post(`/login`, data).then((res) => res.data);
}; //

export const updateBizInfoFetcher = (data: {}) =>
  api2.patch(``, data).then((res) => res.data);

export const updateloginInfoFetcher = (data: {}) =>
  api2.patch(``, data).then((res) => res.data);

//////////////// MY INFO ////////////////
export const getUserInfoFetcher = (qs: string) =>
  api2.get(`/userinfo${qs ? qs : ""}`).then((res) => res.data);
export const updateUserInfoFetcher = (data: updateUserInfo) =>
  api2.post(`/userinfo`, data).then((res) => res.data);
export const updateLoginInfoFetcher = (data: updateLoginInfo) =>
  api2.put(`/userinfo`, data).then((res) => res.data);

export const getManagerFetcher = (qs: string) =>
  api2.get(`/managerinfo${qs ? qs : ""}`).then((res) => res.data);
export const createManagerFetcher = (data: createManager) =>
  api2.post(`/managerinfo`, data).then((res) => res.data);
export const updateManagerFetcher = (data: updateManager) =>
  api2.put(`/managerinfo`, data).then((res) => res.data);
export const deleteManagerFetcher = (data: deleteManager) =>
  api2.delete(`/managerinfo`, { data }).then((res) => res.data);

//////////////// CATEGORY ////////////////

export const getCategoryFetcher = (qs: string) =>
  api2.get(`/category${qs ? qs : ""}`).then((res) => res.data);

//////////////// PRODUCT ////////////////

export const getProductFetcher = (qs?: string) => {
  return api2.get(`/product${qs ? qs : ""}`).then((res) => res.data);
};

//////////////// MYPAGE ////////////////

export const getMyPageFetcher = (data?: mypage) =>
  api2.post(`/mypage`, data).then((res) => res.data);

export const orderCancelFetcher = (data: ordercancel) =>
  api2.post(`/payment/cancel`, data).then((res) => res.data);

//////////////// CART ////////////////
export const createCartFetcher = (data: any) =>
  api2.post(`/cart`, data).then((res) => res.data);

export const getCartFetcher = (qs?: string) =>
  api2.get(`/cart${qs ? qs : ""}`).then((res) => res.data);

export const updateCartFetcher = (data: any) =>
  api2.put(`/cart`, data).then((res) => res.data);

export const deleteCartFetcher = (data: any) => {
  return api2
    .delete(`/cart`, {
      data,
    })
    .then((res) => res.data);
};

//////////////// PAYMENT ////////////////
export const paygetcartinfoFetcher = (qs?: string) => {
  return api2.get(`/paygetcartinfo${qs || ""}`).then((res) => res.data);
};

export const preprocessFetcher = (body: any) => {
  return api2.post(`/payment/preprocess`, body).then((res) => res.data);
};

export const deliveryfeeFetcher = (qs: string) => {
  return api2.get(`/deliveryfee${qs || ""}`).then((res) => res.data);
};
// MOBILE
export const paymentRegisterFetcher = (data: register) => {
  return api2.post(`/payment/register`, data).then((res) => res.data);
};

//////////////// FOOTER ////////////////
export const footerFetcher = (client?: string) =>
  api2
    .get(`/info${client ? "?as_clnt=" + client : ""}`)
    .then((res) => res.data);

//////////////////////////////////////////////////////////
////////////////////////// TYPE //////////////////////////
//////////////////////////////////////////////////////////
export interface loginData {
  as_clnt: "10000" | "20000";
  as_userid: string;
  as_userpw: string;
  as_ipaddr: string;
  as_pcinfo: string;
}

export interface createManager {
  as_clnt?: string;
  as_cust?: string;
  as_jsondata?: string;
}
export interface updateManager {
  as_clnt?: string;
  as_cust?: string;
  as_jsondata?: string;
}
export interface deleteManager {
  as_clnt?: string;
  as_cust?: string;
  as_jsondata?: string;
}

export interface updateLoginInfo {
  as_clnt?: string;
  as_cust?: string;
  as_jsondata?: string;
}

export interface updateUserInfo {
  as_clnt?: string;
  as_cust?: string;
  as_jsondata?: string;
}

export interface preprocess {
  as_clnt: string;
  as_cust: string;
  as_zip: string;
  as_addr: string;
  as_addr_dtl: string;
  as_empnm: string;
  as_telno: string;
  as_email: string;
  as_dlvfee: number;
  as_totalamt: number;
  as_orderamt: number;
  as_jsondata: string;
  as_paymethod: string;
}
export interface register {
  as_clnt: string;
  as_cust: string;
  site_cd: string;
  ordr_idxx: string;
  good_mny: string;
  good_name: string;
  pay_method: string;
  Ret_URL: string;
  escw_used: string;
  user_agent: string;
}

export interface preprocessSub {
  as_recid: number;
  as_itm: string;
  as_ordqty: number;
  as_ordprc: number;
  as_ordamt: number;
}

export interface ordercancel {
  as_clnt: string;
  as_cust: string;
  site_cd: string;
  tno: string;
  mod_type: string;
  mod_mny: number;
  rem_mny: number;
  mod_desc: string;
  ordr_idxx: string;
}

export interface mypage {
  as_clnt?: string;
  as_cust?: string;
  as_action?: string;
  as_frdt?: string;
  as_todt?: string;
}
